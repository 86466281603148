import request from "@/utils/request";

export function selectMaterial(data) {
  return request({
    url: "/admin/base/selectFile",
    method: "get",
    params: data
  });
}
export function getTeacherList(query){
    return request({
        url:'/admin/base/selectTeacher',
        method:'get',
        params: query
        }
    )
}