import request from "@/utils/request";

// 单词分类列表
export function letterCateList(params) {
  return request({
      url: '/admin/base/letterCateList',
      method: 'get',
      params
  })
}

// 删除单词分类
export function letterCateDel(data) {
  return request({
      url: '/admin/base/letterCateDel',
      method: 'delete',
      data
  })
}

// 添加单词分类
export function letterCateAdd(data) {
  return request({
      url: '/admin/base/letterCateAdd',
      method: 'post',
      data
  })
}

// 修改单词分类
export function letterCateUpdate(data) {
  return request({
      url: '/admin/base/letterCateUpdate',
      method: 'post',
      data
  })
}

// 分类单词列表
export function letterCateWordList(params) {
  return request({
      url: '/admin/base/letterCateWordList',
      method: 'get',
      params
  })
}

// 删除单词
export function delLetter(data) {
  return request({
      url: '/admin/french/delLetter',
      method: 'delete',
      data
  })
}