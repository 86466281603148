<template xmlns:>
 <div class="chapterBody">
   <div class="titles">课程章节</div>
<button type="button" aria-label="Close" class="el-dialog__headerbtn" @click="eddChapterDialog.editchapterVisible = false"><i class="el-dialog__close el-icon el-icon-close" ></i></button>
  <div class="flex">
  <el-row :span="4">
  <div class="flex-left">
    <ul class="is-center">
      <li class="cateHeader">全部分类</li>
      <li class="el-background"
        v-for="(item,index) in chapterTable.data"
        :key="index"@click="getSectionData(item)">
       <i class="icon iconfont icon-icon_Chapter" style="font-size: medium;"></i>
       {{ item.lib_name }}
        <i class="icon iconfont icon-shanchu" style="padding-right: 10px;float: right;" @click="removeTab(item.id)"></i>
        <i class="icon iconfont icon-bianji" style="padding-right:5px;float: right;" @click="eddChapter(item)"></i>
      </li>

    <li class="cateButton" @click="addDialog.adddialogVisible = true">添加章节</li>
      </ul>
    </el-table>
  </div>
</el-row>
<el-row :span="4">
  <div class="flex-right">
    <el-table
      :data="sectionTable.data"
      border
      class="table"
      ref="multipleSelection"
      @selection-change="handleSelectionChange"
    >
      <el-table-column prop="chapter_name" label="小节名" align="center"></el-table-column>
      <el-table-column label="素材" align="center" width="180">
        <template slot-scope="scope">
          <div v-for="item in scope.data" v-bind:key="item">
            <span v-if="scope.data.length > 1">{{item.goods_name}}</span>
            <span v-else>{{item.goods_name}}</span>
          </div>
        </template>
      </el-table-column>
     <el-table-column label="是否解锁" align="center" width="120">
        <template slot-scope="scope">
          <el-tag :type="scope.row.is_enable=='1'?'success':'danger'">
            <div v-if="scope.row.is_enable=='1'">是</div>
            <div v-else>否</div>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="是否试听" align="center" width="120">
        <template slot-scope="scope">
          <el-tag :type="scope.row.is_try=='0'?'success':'warning'">
            <div v-if="scope.row.is_try > 0">是</div>
            <div v-else>否</div>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="220">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            @click="handleItemSectionEdit(scope.$index, scope.row)"
          >编辑</el-button>
          <el-button
            type="text"
            icon="el-icon-delete"
            class="red"
            @click="handleItemSectionDelete(scope.$index, scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
      <el-button class="sectionButton" @click="addSectionDialog.addSectionVisible = true">添加小节</el-button>
    </div>
  </el-row>

<!-- 章节编辑 -->
   <el-dialog title="章节编辑" :visible.sync="editChapterDialog" style="padding:100px" width="50%">
      <el-form :model="editChapterForm" ref="editChapterForm">
        <el-form-item label="章节名称:" label-width="100px" prop="lib_name">
          <el-input placeholder="请输入章节名称" v-model="editChapterForm.lib_name" autocomplete="off"></el-input>
        </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editChapterDialog = false">取 消</el-button>
        <el-button type="primary" @click="editChapterNow">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 小节编辑 -->
    <el-dialog title="小节编辑" :visible.sync="eddSectionDialog.editDialogVisiable" style="padding:100px" width="50%">
      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">小节名称：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="请输入内容"
            v-model="eddSectionDialog.chapter_name"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <span style="height:50px;line-height:50px">描述：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="请输入内容"
            v-model="eddSectionDialog.desc"
          ></el-input>
        </el-col>
      </el-row>
      <el-row style="margin: 10px 0 12px 0">
        <el-col :span="4">
          <span>选择文件：</span>
        </el-col>
        <el-col :span="20">
          <el-button
            class="avatar-uploader"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            @click="popup = true"
            v-model="eddSectionDialog.file_id"
            >
            <img v-if="fileUrl" :src="fileUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-button>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="eddSectionDialog.editDialogVisiable = false">取 消</el-button>
        <el-button type="primary" @click="handleEditSection()">确 定</el-button>
      </span>
    </el-dialog>

<!-- 章节添加 -->
    <el-dialog
      title="添加章节"
      :visible.sync="addDialog.adddialogVisible"
      style="padding:100px"
      width="50%"
    >
      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">章节名称：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="请输入内容"
            v-model="addDialog.addChapterName"
          ></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog.adddialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAddChapter">确 定</el-button>
      </span>
    </el-dialog>

    <!-- //小节添加 -->
    <el-dialog
      title="添加小节"
      :visible.sync="addSectionDialog.addSectionVisible"
      style="padding:100px"
      width="50%"
    >
      <el-row>
        <el-col :span="4">
          <span style="line-height:50px">小节名称：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="请输入内容"
            v-model="addSectionDialog.chapter_name"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <span style="height:50px;line-height:50px">描述：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="请输入内容"
            v-model="addSectionDialog.chapter_desc"
          ></el-input>
        </el-col>
      </el-row>
      <el-row style="margin: 10px 0 4px 0">
      <el-col :span="4">
        <span>选择文件：</span>
      </el-col>
      <el-col :span="20">
        <el-button
          class="avatar-uploader"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          @click="popup = true"
          v-model="addSectionDialog.file_id"
          >
          <img v-if="fileUrl" :src="fileUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-button>
      </el-col>
    </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addSectionDialog.addSectionVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAddSection">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 素材选择弹窗 -->
  <el-dialog :visible.sync="popup" width="60%">
       <div class="headers">
         <ul>
           <li
             :class="{ selected: tabs == index }"
             @click="toggletabs(index, item.file_type)"
             v-for="(item, index) in tab"
             :key="index"
           >
             {{ item.name }}
           </li>
         </ul>
       </div>
       <div class="search">
         <div class="search-left">
           <el-form :inline="true" label-width="80px" ref="search">
             <el-form-item label="素材名称：" label-width="100px">
               <el-input
                 placeholder="请输入素材名"
                  v-model="queryparms.file_name"
               ></el-input>
             </el-form-item>
             <el-form-item>
               <el-button type="primary" @click="toQuery">查询</el-button>
             </el-form-item>
           </el-form>
         </div>
       </div>
       <div class="material" @click="selectImg(item)" v-for="(item,index) in materialList" :key="index">
        <li :class="{selectedImg: item.vv_selected}">
         <img
           src="../assets/img/mp3.jpg"
           alt=""
           v-if="item.file_type === 'audio'"
         />
         <img :src="item.avthumb" alt="" v-else />
         <p>{{ item.file_name }}</p>
           </li>
           <div class="types">
             <div class="types-left">
               <img
                 src="../assets/img/material.png"
                 alt=""
                 v-if="item.file_type === 'image'"
               />
               <img
                 src="../assets/img/图层 5.png"
                 alt=""
                 v-else-if="item.file_type === 'video'"
               />
               <img src="../assets/img/图层 6.png" alt="" v-else />
               <span>{{ item.file_size }} </span>
             </div>
           </div>
        </div>
       <span slot="footer" class="dialog-footer">
         <el-button @click="popup = false">取 消</el-button>
         <el-button type="primary" @click="addMaterial">确 定</el-button>
       </span>
       <div class="pagination">
         <span></span>
           <el-pagination
           @size-change="materialSizeChange"
           @current-change="materialCurrentChange"
           :current-page="queryparms.page"
           :page-sizes="[12, 24, 36, 48]"
           :page-size="queryparms.limit"
           layout="total, sizes, prev, pager, next, jumper"
           :total="total"
           algin:="center"
           ></el-pagination>
       </div>
     </el-dialog>
  </div>
  </div>
</div>
</template>

<script>
import store from "@/store";
import { warningDialog } from "@/utils/dialog";
import {
  getChapterList,
  editChapters,
  addChapter,
  delChapter,
  getSectionList,
  addSection,
  editSections,
  delSection
} from "@/api/chapter";
import { selectMaterial } from "@/api/select";
import { timestampToTime } from "@/utils/timeUtils";

export default {
  name: "chapter",
  data() {
    return {
      //编辑章节
      editChapterDialog: false,
      editChapterForm: {
        id: "",
        lib_name: ""
      },
      //编辑小节
      eddSectionDialog: {
        edSectionId: null,
        editDialogVisiable: false,
        lib_id: 1,
        chapter_name: "",
        desc: "",
        lib_name: "",
        thumb_id: ""
      },
      editSectionDialog: {
        id: -1,
        editDialogVisiable: false
      },
      chapterTable: {
        id: "",
        pageIndex: 1,
        limit: 5,
        pageTotal: 0,
        data: []
      },
      sectionTable: {
        pageIndex: 1,
        limit: 5,
        pageTotal: 0,
        data: []
      },
      select: {
        name: "",
        teacherSelId: "",
        statusSelId: "",
        isSelect: false,
        status: [
          {
            value: 1,
            name: "正常"
          },
          {
            value: 0,
            name: "禁止"
          }
        ]
      },
        // 添加章节
      addDialog: {
        id: "",
        adddialogVisible: false,
        goods_id: '',
        lib_name: ""
      },
      // 添加小节
      addSectionDialog: {
        addSectionVisible: false,
        lib_id: 1,
        chapter_name: '',
        chapter_desc: '',
        file_id: '',
      },
      eddChapterDialog: {
        editchapterVisible: false
      },
      // 素材弹窗
      rules: {
        file_name: [
          {
            required: true,
            message: "素材名称不能为空",
            trigger: "blur"
          },
          { min: 1, max: 8, message: "长度在 1 到 8 个字符", trigger: "blur" }
        ],
        },
      queryparms: {
        page:1,
        limit:12,
        file_name: null,
        file_type: null,
        cate_id: null
      },
      checkedList: [],
      list: [],
      popup: false,
      materialList: '',
      ruleForm: {
        name: ""
      },
      editForm: {
        title: "",
        id: null
      },
      unpdateDialog: false,
      cur: -1,
      tabs: 0,
      tab: [
        { name: "全部", file_type: null },
        { name: "图片", file_type: "image" },
        { name: "视频", file_type: "video" },
        { name: "音频", file_type: "audio" }
      ],
      fileUrl: '',
      selectid:[],
      selectitem:[]
    };
  },

  methods: {
    getChapterData() {
      var id = this.goods_id;
      var params = {
        goods_id: id
      };
      getChapterList(params).then(res => {
        this.chapterTable.data = res.data;
        this.pageTotal = res.data.totalRows;
        this.getMaterialList()
      })
      },
    getSectionData(item){
      sessionStorage['libId'] = item.id;
      this.$emit('select',item);
      var params = {
      lib_id: item.id
      };
      getSectionList(params).then(res => {
        this.sectionTable.data = res.data;
        this.sectionTable.pageTotal = res.data.totalRows;
        this.getChapterData();
      });
      var libId = item.id;
      return libId;
   },

   // 编辑章节
   eddChapter(item) {
     this.editChapterForm.id = item.id;
     this.editChapterForm.lib_name = item.lib_name;
     this.editChapterDialog = true;
   },
   // 点击立即编辑章节
   editChapterNow() {
     this.$refs.editChapterForm.validate(valid => {
       if (valid) {
         editChapters(this.editChapterForm).then(res => {
           if (res.code !== 1) return this.$message.error("编辑章节失败,请重试");
           this.$message.success("编辑章节成功！");
           this.editChapterDialog = false;
           this.getChapterData();
         });
       } else {
         return false;
       }
     });
   },
 // 点击item编辑小节
 handleItemSectionEdit(index, row) {
   this.eddSectionDialog.lib_id = this.sectionTable.data[index].id;
   this.eddSectionDialog.chapter_name = this.sectionTable.data[index].chapter_name;
   this.eddSectionDialog.desc = this.sectionTable.data[index].chapter_desc;
   this.eddSectionDialog.is_try = this.sectionTable.data[index].is_try;
   this.eddSectionDialog.try_time = this.sectionTable.data[index].try_time;
   this.eddSectionDialog.thumb_id = this.sectionTable.data[index].thumb_id;
   this.eddSectionDialog.editDialogVisiable = true;
 },
 // 保存item编辑小节
 handleEditSection() {
   this.eddSectionDialog.editDialogVisiable = false;
   var data = {
     id: this.eddSectionDialog.lib_id,
     chapter_name: this.eddSectionDialog.chapter_name,
     chapter_desc: this.eddSectionDialog.chapter_desc,
     file_id:  this.eddSectionDialog.file_id
   };
   editSections(data).then(res => {
     this.getSectionData();
   });
 },

    //删除章节操作
    handleItemCapterDelete(index, row) {
      warningDialog("确定要删除吗？").then(() => {
        //删除操作
        this.delChapter(index);
      });
    },
    //删除小节操作
    handleItemSectionDelete(index, row) {
      warningDialog("确定要删除吗？").then(() => {
        //删除操作
        this.delSection(index);
      });
    },

    //删除章节
    delChapter(index, row) {
      delChapter(this.chapterTable.data[index].id).then(res => {
        this.getChapterData();
      });
    },
    //删除小节
    delSection(index) {
      delSection(this.sectionTable.data[index].id).then(res => {
        this.getSectionData();
      });
    },
    delAllSelection() {
      var _this = this;
      warningDialog("确定要删除吗？").then(() => {
        _this.delSelectSection();
      });
    },
    async removeTab(id) {
      const result = await this.$confirm(
        "请问是否要永久删除该章节?",
        "删除提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).catch(err => err);
      if (result !== "confirm") return this.$message.info("您已经撤销删除");
      delChapter(id).then(res => {
        this.getChapterData();
        this.$message.success("删除章节成功");
      });
    },
    handleSelectionChange(val) {
      this.addDialog.selectItemIds = val;
    },
    handlePageCurrpageChange(val) {
      this.sectionTable.pageIndex = val;
      if (this.isSelect) {
        this.selectSectionList();
      } else {
        this.getChapterData();
      }
    },
    handlelimitChange(val) {
      this.sectionTable.limit = val;
      if (this.isSelect) {
        this.getChapterData();
      } else {
        this.selectSectionList();
      }
    },
    //添加章节
    handleAddChapter() {
      var cateId = this.$route.params.goods_id;
      this.addDialog.adddialogVisible = false;
      var data = {
        goods_id:cateId,
        lib_name: this.addDialog.addChapterName
      };
      addChapter(data).then(res => {
        this.getChapterData();
      });
      this.addDialog.addChapterName = "";
    },
    // 添加小节
    handleAddSection() {
      var libId=sessionStorage['libId']
      console.log(libId)
      this.addSectionDialog.addSectionVisible = false;
      var data = {
        lib_id:libId,
        chapter_name: this.addSectionDialog.chapter_name,
        chapter_desc: this.addSectionDialog.chapter_desc,
        file_id:this.addSectionDialog.file_id
      };
      addSection(data).then(res => {
        this.getSectionData();
      });
    },

    handleAvatarSuccess(res, file) {
        this.fileUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {

    },
    // 素材弹窗
    checkTab (id) {
          let index = this.checkedList.indexOf(id)
          if (index === -1) {
            this.checkedList.push(id)
          } else {
            this.checkedList.splice(index, 1)
          }
      },
      selectMaterial(){
        this.popup = false;
        selectMaterialId=this.item.id
      },
        // 素材弹窗分类切换
       toggleClass(i, item) {
         this.cur = i;
         this.queryparms.cate_id = item;
         this.addFileForm.cate_id=item
         this.addAvidoForm.cate_id=item
         this.selectMaterial()
       },

       materialSizeChange(size) {
         this.queryparms.limit=size
         this.selectMaterial()
       },
       materialCurrentChange(page){
         this.queryparms.page=page
         this.selectMaterial()
       },
       toggletabs(i, elm) {
         this.tabs = i;
         this.queryparms.file_type =elm;
         this.getFileList()
       },
       toQuery(){
         this.getFileList()
       },
        selectImg(item){
           console.log(item)
             if(item.vv_selected){
               item.vv_selected = false;

               this.selectid=this.selectid.filter(function(age){
                 return item.id != age
               })
                this.selectitem=""
             }else{
               this.$set(item, 'vv_selected', true)
               this.selectid.push(item.id);
               this.selectitem=item.avthumb;
               this.selectitem1 = item.avthumb;
             }
            //  console.log(this.selectid)
           },
          getMaterialList() {
             selectMaterial(this.queryparms).then(res => {
               this.total=res.data.total;
               this.materialList = res.data;
             });
           },
        addMaterial() {
          this.popup = false;
          this.eddSectionDialog.file_id = this.selectid.toString();
          this.addSectionDialog.file_id = this.selectid;

           console.log(this.eddSectionDialog.file_id);
           console.log(this.addSectionDialog.file_id);
          this.fileUrl = this.selectitem;
        },

    refresh() {
      this.getChapterData();
      this.getSectionData();
    }
  },
  mounted() {
    this.refresh();
  }
};
</script>

<style scoped>
  .chapterBody {
    background-color: #ffffff;
        position: fixed;
        z-index: 2009;
        top: 98px;
        overflow: auto;
        /* opacity: .9; */
        left: 211px;
        -moz-box-shadow:0px 2px 5px #666666; -webkit-box-shadow:0px 2px 5px #666666; box-shadow:0px 2px 5px #666666;
  }
  .titles {
      padding: 26px;
    }
.handle-box {
  float: right;
  position: relative;
  margin-right: 10px;
}
  .iconfont{
    color: #3dccab;
  }
.flex {
     display: flex; /* 弹性布局 */
     margin: 0 auto;
    align-content: space-between;
}
.flex-left {
  width: 380px;
  margin: 20px;
  border: 1px solid #efefef;
  }

.flex-right {
  width: 1221px;
  margin: 19px;
  }

.cateHeader {
    background-color: #f5f7fa;
    height: 34px;
    padding-top: 14px;
    text-align: center;
}

.is-center {
  text-align: left;
  list-style-type: none;
}
.cateButton {
  background-color: #38a28a;
  text-align: center;
  height: 34px;
  color: #fff;
  padding-top: 14px;
}
.sectionButton {
    width: 200px;
    background-color: #1AA779;
    display: inline-block;
    text-align: center;
    margin-left: 40%;
    margin-top: 60px;
    color: #fff
}
.el-background {
  background-color: #fff;
  height: 36px;
  padding: 14px 0  0 14px;
  border-bottom: 1px solid #EBEEF5;
}
.red {
  color: #ff0000;
}
.inputText {
  position: relative;
  display: inline-block;
}
.radiusbg {
  border-radius: 2px;
  background-color: #ffffff;
  padding: 0px 0px 10px 0px;
  margin-top: 10px;
}
.rightview {
  display: inline-block;
}
.table {
  margin-top: 10px;
  background: #ffffff;
}

li {
  list-style: none;
  cursor: pointer;
}

.left ul {
  font-size: 14px;
  color: #333333;
}
.left ul li {
  padding-left: 20px;
  line-height: 50px;
  border-bottom: 1px solid #eeeeee;
}
.left ul .selected {
  background-color: #f5f7fa;
}
.right {
  flex: 10;
  margin-left: 20px;
}
.headers {
  border-bottom: 1px solid #eeeeee;
  width: 100%;
  height: 50px;
}
.headers ul {
  width: 100%;
}
.headers .selected {
  color: #38a28a;
  border-bottom: 1px solid #38a28a;
}
.headers li {
  height: 50px;
  float: left;
  margin-right: 60px;
}
::v-deep .el-form-item__label {
  color: #333333;
}
.ck {
  float: right;
}
.ck i {
  margin-right: 10px;
}
.ck i:nth-child(1) {
  color: #3fbfa2;
}
.ck i:nth-child(2) {
  color: red;
}
.navs .search .inputs {
  width: 180px;
}
::v-deep .el-form-item__content {
  vertical-align: 0;
  /* height: 30px; */
}

.search {
  display: flex;
  justify-content: space-between;
  line-height: 70px;
}

.contents {
  width: 999px;
  padding: 20px;
}
.material {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 180px;
  margin: 0 20px 0 0;
  text-align: center;
}
.material img {
  width: 100%;
  height: 118px;
}
.material p {
  font-size: 14px;
  color: #333333;
  line-height: 26px;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.material .types {
  display: flex;
  justify-content: space-between;
}
.material .types .types-left img {
  width: 18px;
  height: 18px;
}
.material .types span {
  color: #969696;
  font-size: 12px;
  margin-left: 10px;
}
.pagination {
  display: flex;
  justify-content: space-between;
}

.types .types-right i:nth-child(1) {
  margin-right: 10px;
}
.types .types-right i:nth-child(2) {
  color: red;
}
.el-col {
  cursor: pointer;
}
.icon-shipin {
  color: #feaf65;
  font-size: 116px;
}

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
    }
.selectedImg{
  border: 2px solid #2fe9fc;
  z-index:99;
}
</style>
