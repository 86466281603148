<template xmlns:>
  <div class="container">
    <div class="handle-box">
      <el-form ref="form" :inline="true">
        <el-form-item label="新旧词书">
          <el-select
            v-model="typeid"
            placeholder="请选择"
            clearable
            width="100px"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in typeList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd">创建词书</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="courseTable.data"
      border
      class="table"
      v-loading="loading"
    >
      <el-table-column prop="id" label="编号" width="100" align="center"></el-table-column>
      <el-table-column prop="name" label="词书名称" align="center"></el-table-column>

      <el-table-column label="封面图" align="center">
        <template slot-scope="scope">
          <el-image
            class="table-td-thumb"
            :src="scope.row.http_cover"
            :preview-src-list="[scope.row.http_cover]"
            style="width:83px;height:117px"
          ></el-image>
        </template>
      </el-table-column>

      <el-table-column label="是否上架" align="center" width="120">
        <template slot-scope="scope">
          <el-tag :type="scope.row.open=='1'?'success':'danger'">
            <div v-if="scope.row.open == 1">上架</div>
            <div v-else>下架</div>
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope" >
          <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="handleItemEdit(scope.$index, scope.row)"
              size="small"
            ></el-button>
          <el-button type="primary" icon="el-icon-tickets" size="small" circle  @click="handleItemChapter(scope.$index, scope.row)"></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            circle
            @click="handleItemDelete(scope.$index, scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加词书 -->
    <el-dialog
      :title="addDialogTitle"
      :visible.sync="addDialog.adddialogVisible"
      style="padding:100px"
      width="50%"
      top=0
      :close-on-click-modal=false
      @close="closedAdd"
    >
      <el-form :model="addDialog"  ref="addForm">
        <el-form-item el-form-item label="词书名称" label-width="100px">
            <el-input
              type="text"
              v-model="addDialog.name"
              placeholder="请输入词书名称"
            />
        </el-form-item>
        <el-form-item el-form-item label="封面" label-width="100px">
            <el-row>
              <el-upload
                class="avatar-uploader"
                action="123"
                :show-file-list="false"
                accept="image/*"
                :before-upload="beforeThumbUpload"
                style="width:83px;height:117px"
              >
                  <img v-if="addImages" :src="addImages" class="avatar" style="width: 100%;height:100%"/>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-row>
            <el-row>
              <div class="tips">上传封面尺寸约为13:9且小不大于10MB</div>
            </el-row>
        </el-form-item>
        <el-form-item el-form-item label="是否上架" label-width="100px">
          <el-radio v-model="addDialog.open" :label="1">上架</el-radio>
          <el-radio v-model="addDialog.open" :label="0">下架</el-radio>
        </el-form-item>
        <el-form-item label-width=40% style="margin-top: 20px;">
          <el-button type="primary" v-if="isAdd=='add'" @click="addBookNow" >立即添加</el-button>
          <el-button type="primary" v-else @click="editBookNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 添加章节 -->
    <el-dialog
      :title="this.typeid == 3 ? '添加章节' : '添加关卡'"
      :visible.sync="addChapter.adddialogVisible"
      style="padding:100px"
      width="50%"
      @close="closeAddChapter"
    >
      <el-row>
        <el-col :span="4" v-if="this.typeid == 3">
          <span style="line-height:50px">章节名称：</span>
        </el-col>
        <el-col :span="4" v-if="this.typeid == 30">
          <span style="line-height:50px">关卡名称：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="请输入内容"
            v-model="addChapter.addChapterName"
          ></el-input>
        </el-col>
      </el-row>
      <el-row v-if="this.typeid == 30">
        <el-col :span="4">
          <span style="line-height:50px">关卡等级：</span>
        </el-col>
        <el-col :span="20">
          <el-input
            style="height:50px;line-height:50px"
            placeholder="请输入内容"
            v-model="addChapter.level"
          ></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addChapter.adddialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAddChapter">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加单词 -->
    <el-dialog
      :title="addFileDialogTitle"
      :visible.sync="addSectionDialog.addSectionVisible"
      style="padding:100px"
      width="50%"
      @close="closed"
    >
      <template>
        <el-form :model="addLetterForm" ref="ruleForm" label-width="100px">
          <el-form-item label="单词" prop="word" label-width="50px">
            <el-input v-model="addLetterForm.word"></el-input>
          </el-form-item>
          <el-form-item label="中文" prop="note" label-width="50px">
            <el-input v-model="addLetterForm.note"></el-input>
          </el-form-item>
          <el-form-item label="词性" prop="examples" label-width="50px">
            <el-input v-model="addLetterForm.examples[0].sentence"></el-input>
          </el-form-item>
          <el-form-item el-form-item label="音频" label-width="50px">
            <div v-if="audio_url && fileAudioName == ''">
              <audio
                :src="audio_url"
                style="cursor: pointer;outline: none;"
                controls="controls"
                ref="audioPlay"
              ></audio>
            </div>
            <el-button type="primary" @click="selectAudio">{{
              isAdds == "add" ? "上传音频" : "修改音频"
            }}</el-button>
            <span class="file-name">{{ fileAudioName }}</span>
            <el-progress
              :percentage="audioProgress"
              v-if="audioProgress"
            ></el-progress>
            <input
              type="file"
              name="audio"
              id=""
              hidden
              ref="audio"
              @change="selectAudioFiles"
              accept="audio/*"
            />
          </el-form-item>
          <el-form-item label-width="40%">
            <el-button type="primary" @click="addNow" v-if="isAdds == 'add'"
              >立即添加</el-button
            >
            <el-button
              type="primary"
              v-else-if="isAdds == 'edit'"
              @click="editNow"
              >立即修改</el-button
            >
          </el-form-item>
        </el-form>
      </template>
    </el-dialog>

    <!-- 章节编辑 -->
    <el-dialog :title="this.typeid == 3 ? '章节编辑':'关卡编辑'" :visible.sync="editChapterDialog" style="padding:100px" width="50%">
      <el-form :model="editChapterForm" ref="editChapterForm">
        <el-form-item :label="this.typeid == 3 ? '章节名：':'关卡名：'" label-width="100px" prop="lib_name">
          <el-input placeholder="请输入名称" v-model="editChapterForm.lib_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="关卡等级：" label-width="100px" prop="level" v-if="this.typeid == 30">
          <el-input placeholder="请输入等级" v-model="editChapterForm.level" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editChapterDialog = false">取 消</el-button>
        <el-button type="primary" @click="editChapterNow">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加题目 -->
    <el-dialog :title="addQuestionTitle" :visible.sync="questionDialog" style="padding:100px" width="50%" @close="closedQuestionDialog">
      <el-form ref="questionForm">
        <el-form-item label="题目类型">
          <el-select
            v-model="questionType"
            placeholder="请选择"
            clearable
            width="100px"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in questionTypeList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="题目单词">
          <el-select v-model="titleArr" multiple placeholder="请选择">
            <el-option
              v-for="item in selectWordsArr"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="干扰选项" v-if="questionType != '听写'">
          <el-select v-model="distractorsArr" multiple placeholder="请选择">
            <el-option
              v-for="item in distractorsArrList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="40%">
          <el-button type="primary" @click="addQuestionNow" v-if="isAddsQuestion == 'add'"
            >立即添加</el-button
          >
          <el-button
            type="primary"
            v-else-if="isAddsQuestion == 'edit'"
            @click="editQuestionNow"
            >立即修改</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 章节大弹框 -->
    <el-dialog  :lock-scroll="true" :visible.sync="eddChapterDialog.editchapterVisible" width="90%" :close-on-click-modal=false @close="closedChapter">
      <div class="top_btn_box">
          <div class="top_btn_left">
            <div class="titles">{{courseName}}</div>
            <div class="cateButton" @click="addChapter.adddialogVisible = true" v-if="this.typeid == 3">添加章节</div>
            <div class="cateButton" @click="addChapter.adddialogVisible = true" v-else>添加关卡</div>
          </div>
          <el-button :class="isSectionDisabled?'not_sectionButton':'sectionButton'" :disabled="isSectionDisabled" @click="addSection" v-if="isWord">添加单词</el-button>
          <el-button :class="isSectionDisabled?'not_sectionButton':'sectionButton'" :disabled="isSectionDisabled" @click="addQuestion" v-if="isQuestion">添加题目</el-button>
      </div>
      <div class="flex_box">
        <div class="flex-left" style="overflow:auto;height:600px">
          <el-table
            :data="chapterTable.data"
            class="table"
            :highlight-current-row="true"
            border
          >
            <el-table-column prop="id" label="ID" align="center" width="60"></el-table-column>
            <el-table-column prop="name" :label="this.typeid == 3?'章节名':'关卡名'" align="center" key="id"></el-table-column>
            <el-table-column prop="level" label="关卡等级" align="center" v-if="this.typeid == 30"></el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="small"
                  circle
                  @click="eddChapter(scope.row)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="small"
                  circle
                  @click="removeTab(scope.row.id)"
                ></el-button>
                <el-button
                  type="success"
                  size="small"
                  circle
                  @click="getSectionData(scope.row)"
                >词</el-button>
                <el-button
                  type="success"
                  size="small"
                  circle
                  @click="getQuestionData(scope.row)"
                  v-if="scope.row.type == 30"
                >题</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex-right" style="overflow:auto;height:600px">
          <!-- 单词列表 -->
          <el-table
            :data="sectionTable.data"
            border
            class="table"
            style="width: 100%;"
            ref="multipleSelection"
            v-show="isWord"
          >
            <el-table-column prop="word" label="单词"  align="center"></el-table-column>
            <el-table-column prop="note" label="中文"  align="center"></el-table-column>
            <el-table-column prop="examples[0].sentence" label="词性"  align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  type="success"
                  icon="el-icon-video-play"
                  circle
                  @click="toRadioPlay(scope.row)"
                  size="small"
                ></el-button>
                <!-- <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  @click="handleItemEditWord(scope.$index, scope.row)"
                  size="small"
                ></el-button> -->
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="small"
                  circle
                  @click="handleItemSectionDelete(scope.$index, scope.row)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 题目列表 -->
          <el-table
            :data="questionList"
            border
            class="table"
            style="width: 100%;"
            ref="multipleSelection"
            v-show="isQuestion"
          >
            <el-table-column prop="id" label="ID"  align="center"></el-table-column>
            <el-table-column prop="title_class" label="类型"  align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.title_class == 'other_to_ch' && scope.row.titleLength > 1">法译中</div>
                <div v-if="scope.row.title_class == 'ch_to_other' && scope.row.titleLength > 1">中译法</div>
                <div v-if="scope.row.title_class == 'ch_to_other' && scope.row.titleLength == 1">听写</div>
                <div v-if="scope.row.titleLength == 1 && scope.row.optionArr.err.length > 1">选择</div>
              </template>
            </el-table-column>
            <el-table-column prop="stem" label="题目"  align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  @click="handleItemEditQuestion(scope.$index, scope.row)"
                  size="small"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="small"
                  circle
                  @click="handleItemDeleteQuestion(scope.$index, scope.row)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { warningDialog } from "@/utils/dialog";
import { selectMaterial } from "@/api/select";
import {
  letterBookList,
  letterBookDel,
  letterBookAdd,
  letterBookUpdate,
  letterBookWordList,
  letterBookWord
} from "@/api/French/french_wordList";
import {
  updateLetter,
  getReadCosToken
} from "@/api/French/french_alphabet";
import {
  letterCateList,
  letterCateWordList
} from "@/api/French/french_wordResources";
import {addExamTitle,titleListByThemeId,updateTitleById,delTitleById} from "@/api/French/questionbank_management.js";
var COS = require("cos-js-sdk-v5");
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import chapter from "../../../components/chapter.vue";
export default {
  name: "couselist",
  components: {
    quillEditor,
    chapter,
  },
  data() {
    return {
      loading: false,
      goods_id: "",
      // cateTable: {
      //   tableData: [],
      // },
      // pickerOptions1: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now() - 8.64e7;
      //   },
      // },
      courseTable: {
        title: "",
        goods_id: "",
        page: 1,
        limit: 10,
        pageIndex: 1,
        total: 0,
        data: [],
        search_date: [],
      },
      classification: null,
      //课程分类
      courseCate: [],
      select: {
        id: "",
        title: "",
        isSelect: false,
      },
      //选择老师
      selectTeacher: [],
      course: {
        title: "",
        data: [],
        isSelect: false,
      },
      // 添加课程
      addDialog: {
        open: 1,
        type: null,
        name: "",
        parent_id: 0,
        cover: "",
      },

      //编辑
      eddDialog: {
        id: null,
        cate_id: "",
        desc: "",
        content: "",
        price: "",
        thumb_id: "",
        file_id: "",
        expire: "",
        views_vt: "",
        buy_num_vt: "",
        is_open: null,
        is_best: null,
        is_hot: null,
        is_new: null,
        is_enable: null,
        is_1V1: null,
        teacher_id: null,
        editVisible: false,
        goods_name: "",
        edStatusSelId: null,
        chapterIds: [],
        score: null,
      },
      eddChapterDialog: {
        editchapterVisible: false,
      },
      times: null,
      // content: null,
      editorOption: {},
      queryparms: {
        page: 1,
        limit: 12,
        file_name: null,
        file_type: null,
        cate_id: null,
        teacher: null,
      },
      checkedList: [],
      list: [],
      popup: false,
      popup2: false,
      materialList: "",
      ruleForm: {
        name: "",
      },
      editForm: {
        title: "",
        id: null,
      },
      unpdateDialog: false,
      cur: -1,
      tabs: 0,
      selecteds: "",
      selecteds2: "",
      selecteds3: "",
      // 缩略图
      imageUrl: "",
      //选中缩略图文件名称
      check_edit_file_name: "",
      check_add_file_name: "",
      //选中添加的课程视频
      check_video: "",
      //编辑小节中的视频
      edit_video: "",
      selectid: [],
      selectitem: [],
      // 素材
      imageUrl2: "",
      selectid2: [],
      selectitem2: [],

      //编辑章节
      editChapterDialog: false,
      editChapterForm: {
        id: "",
        lib_name: "",
        level: "",
      },
      //编辑小节
      eddSectionDialog: {
        edSectionId: null,
        editDialogVisiable: false,
        lib_id: 1,
        chapter_name: "",
        lib_name: "",
        thumb_id: "",
        unlock_time: "",
        is_task: 1,
      },
      editSectionDialog: {
        id: -1,
        editDialogVisiable: false,
      },
      chapterTable: {
        id: "",
        pageIndex: 1,
        limit: 5,
        pageTotal: 0,
        data: [],
      },
      sectionTable: {
        pageIndex: 1,
        limit: 5,
        pageTotal: 0,
        data: [],
      },
      select: {
        name: "",
        teacherSelId: "",
        statusSelId: "",
        isSelect: false,
        status: [
          {
            value: 1,
            name: "正常",
          },
          {
            value: 0,
            name: "禁止",
          },
        ],
      },
      // 添加章节
      addChapter: {
        id: "",
        adddialogVisible: false,
        // goods_id: "",
        // lib_name: "",
        level: "",
      },
      goodsDetailsList: "",
      // 添加小节
      addSectionDialog: {
        addSectionVisible: false,
        // lib_id: "",
        // chapter_name: "",
        // file_id: "",
        // thumb_id: "",
        // unlock_time: "",
        // is_task: 1,
        word: "",
      },
      eddChapterDialog: {
        editchapterVisible: false,
        goods_id: "",
      },
      // 素材弹窗
      rules: {
        file_name: [
          {
            required: true,
            message: "素材名称不能为空",
            trigger: "blur",
          },
          {
            min: 1,
            max: 8,
            addMaterialmessage: "长度在 1 到 8 个字符",
            trigger: "blur",
          },
        ],
        orderby: [
          {
            required: true,
            message: "排序值不能为空",
            trigger: "blur",
          },
          { type: "number", message: "排序值必须为数字值" },
        ],
      },
      checkedList: [],
      list: [],
      popup: false,
      materialList: "",
      ruleForm: {
        name: "",
      },
      editForm: {
        title: "",
        id: null,
      },
      goodsDetailsLists: "",
      unpdateDialog: false,
      cur: -1,
      tabs: 0,
      tab: [
        { name: "全部", file_type: null },
        { name: "图片", file_type: "image" },
        { name: "视频", file_type: "video" },
        { name: "音频", file_type: "audio" },
      ],
      // fileUrl: '',
      selectid: [],
      selectitem: [],
      MaterialLists: [],
      // 选择素材分页变量
      sucai: {
        cate_id:null,
        file_type: null,
        // file_name:null
        page: 1,
        limit: 15,
      },

      materialListes: [],
      courseName: "",
      addImages: null,
      SectionImageUrl: null,
      editVisibleLoading: false,
      editVisibleText: "拼命加载中",
      setDialog: false,
      setForm: {
        id: null,
        orderby: null,
        target: null,
      },

      // choiceMaterialForm:{
      //   page:1,
      //   limit:15,
      // },
      choiceTotal: 0,
      // detailsData: {
      //   cate: {},
      // }, //详情数据
      // detailsDialog: false, //详情弹框
      // isTaskArr: [
      //   { key: 0, value: "无作业" },
      //   { key: 1, value: "有作业" },
      // ],
      isSectionDisabled: true,
      getMaterialDate: {
        // mold: ""
        cate: null,
      },
      // thumbSrc: "",
      isAdd: "",
      addDialogTitle: "",
      addLetterForm: {
        word: "",
        voice_file: "",
        note: "",
        examples: [
          {
            sentence: ""
          }
        ],
        mold_type: 3,
        book: 1,
        cate_id: null
      },
      audio_url: "",
      fileAudioName: "",
      audioProgress: 0,
      isAdds: "add",
      addFileDialogTitle: "",
      typeList: [
        {name:"新词书",id:30},
        {name:"旧词书",id:3},
      ],
      typeid: 3,
      // 题目
      questionDialog: false,
      isWord:false,
      isQuestion:false,
      isAddsQuestion: "add",
      addQuestionTitle: "",
      questionType: "",
      questionTypeList: [
        {name:"法译中",id:"法译中"},
        {name:"中译法",id:"中译法"},
        {name:"听写",id:"听写"},
      ],
      selectWordsArr: [],
      titleArr: [],
      distractorsArr: [],
      distractorsArrList: [],
      questionList: [],
      titleId: null,
    };
  },
  watch: {
    fileAudioName: function(news, old) {
      if (news != "" && this.addLetterForm.word == "") {
        this.addLetterForm.word = news.split(".")[0];
      }
    },
    typeid: function(news, old) {
      this.getLetterBookList()
    },
    titleArr: function(news,old) {
      if(old.length != 0 && this.selectWordsArr.length > 0){
        this.distractorsArr = [];
        this.distractorsArrList = [];
        this.distractorsArrList = this.selectWordsArr.filter(function(item1) {
            return news.every(function(item2) {
              return item2 != item1.value
            })
        })
      }
    },
  },
  methods: {
    // 确认添加题目
    addQuestionNow(){
      // let distractors = this.sectionTable.data.filter((item)=>{
      //     return this.distractorsArr.some(i=>item.id == i[0])
      // })
      let distractors = this.selectWordsArr.filter((item)=>{
          return this.distractorsArr.some(i=>item.value == i)
      })
      let data = {
        theme_type : 30,
        title_class: "",
        title: this.titleArr.toString(),
        theme_id: sessionStorage.getItem("libId"),
        options: distractors,
        right: "",
      }
      if(this.questionType == "法译中"){
        data.title_class = "other_to_ch";
      }else if(this.questionType == "中译法"){
        data.title_class = "ch_to_other";
      }else if(this.questionType == "听写"){
        data.title_class = "ch_to_other";
      }
      if (data.title_class == "") {
        this.$message.error("题目类型不能为空！");
        return;
      } else if (data.title == "") {
        this.$message.error("题目单词不能为空！");
        return;
      } else if (this.questionType == "听写" && this.titleArr.length > 1) {
        this.$message.error("听写题目只能添加一个单词！");
        return;
      } else if (this.questionType != "听写" && this.titleArr.length == 1 && data.options.length != 3) {
        this.$message.error("选择题需要固定添加三个干扰选项！");
        return;
      }
      console.log(data)
      addExamTitle(data).then(res=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加题目成功!");
        this.getQuestionList(data.theme_id)
        this.questionDialog = false;
      })
    },
    // 确认编辑题目
    editQuestionNow(){
      let distractors = this.selectWordsArr.filter((item)=>{
          return this.distractorsArr.some(i=>item.value == i)
      })
      let data = {
        theme_type : 30,
        title_class: "",
        title: this.titleArr.toString(),
        theme_id: sessionStorage.getItem("libId"),
        options: distractors,
        right: "",
        id: this.titleId,
      }
      if(this.questionType == "法译中"){
        data.title_class = "other_to_ch";
      }else if(this.questionType == "中译法"){
        data.title_class = "ch_to_other";
      }else if(this.questionType == "听写"){
        data.title_class = "ch_to_other";
      }
      if (data.title_class == "") {
        this.$message.error("题目类型不能为空！");
        return;
      } else if (data.title == "") {
        this.$message.error("题目单词不能为空！");
        return;
      } else if (this.questionType == "听写" && this.titleArr.length > 1) {
        this.$message.error("听写题目只能添加一个单词！");
        return;
      } else if (this.questionType != "听写" && this.titleArr.length == 1 && data.options.length != 3) {
        this.$message.error("选择题需要固定添加三个干扰选项！");
        return;
      }
      updateTitleById(data).then(res=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("编辑题目成功!");
        this.getQuestionList(data.theme_id)
        this.questionDialog = false;
      })
    },
    // 关闭添加题目弹窗
    closedQuestionDialog(){
      this.questionType = "";
      this.selectWordsArr = [];
      this.titleArr = [];
      this.distractorsArr = [];
      this.distractorsArrList = [];
    },
    // 点击编辑题目图标
    handleItemEditQuestion(index, row) {
      this.questionDialog = true;
      this.isAddsQuestion = "edit";
      this.addQuestionTitle = "编辑题目";
      this.getlibIds();
      if(row.title_class == "other_to_ch"){
        this.questionType = "法译中";
      }else if(row.title_class == "ch_to_other" && row.titleLength > 1){
        this.questionType = "中译法";
      }else if(row.title_class == "ch_to_other" && row.titleLength == 1){
        this.questionType = "听写";
      }
      this.titleArr = row.title.split(",").map(Number);
      row.optionArr.err.forEach((item)=>{
        this.distractorsArr.push(item.value)
      })
      this.titleId = row.id;
    },
    // 立刻添加单词
    addNow(){
      if (this.addLetterForm.word == "") {
        this.$message.error("单词不能为空！");
        return;
      } else if (this.addLetterForm.note == "") {
        this.$message.error("中文为空！");
        return;
      } else if (this.addLetterForm.examples[0].sentence == "") {
        this.$message.error("词性不能为空！");
        return;
      } else if (this.addLetterForm.voice_file == "") {
        this.$message.error("音频不能为空！");
        return;
      }
      var libId = sessionStorage.getItem("libId");
      letterBookWord({book_id:libId,letter_id:this.selecteds.id,op: "add",...this.addLetterForm}).then(res=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加单词成功!");
        this.addSectionDialog.addSectionVisible = false;
        this.getlibIds();
      })
    },
    //立即编辑单词
    editNow() {
      if (this.addLetterForm.word == "") {
        this.$message.error("单词不能为空！");
        return;
      } else if (this.addLetterForm.note == "") {
        this.$message.error("中文为空！");
        return;
      } else if (this.addLetterForm.examples[0].sentence == "") {
        this.$message.error("词性不能为空！");
        return;
      } else if (this.addLetterForm.voice_file == "") {
        this.$message.error("音频不能为空！");
        return;
      }
      updateLetter(this.addLetterForm).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改成功!");
        this.addFileDialog = false;
        this.getLetterCateWordList(this.addLetterForm.cate_id);
      });
    },
    // 点击选择单词音频
    selectAudio() {
      console.log(this.$refs.audio);
      this.$refs.audio.click();
    },
    // 选中音频
    selectAudioFiles(e) {
      let file = this.$refs.audio.files[0];
      this.fileAudioName = file.name;
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      getReadCosToken({ suffix: file.name }).then(res => {
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
              that.audioProgress = progressData.percent * 100;
            }
          },
          function(err, data) {
            if (data) {
              that.addLetterForm.voice_file = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },
    handleAvatarSuccess(res) {
      this.imageUrl = URL.createObjectURL(index.raw);
    },
    handleSelectionChange(val) {
      this.addDialog.selectItemIds = val;
    },
    // 关闭章节大弹窗
    closedChapter() {
      this.sectionTable.data = [];
      this.selecteds3 = "";
      this.isSectionDisabled = true;
      this.isWord = false;
      this.isQuestion = false;
    },
    // 关闭添加章节弹窗
    closeAddChapter(){
      this.addChapter.addChapterName = "";
      this.addChapter.level = null;
    },
    
    //删除小节操作
    handleItemSectionDelete(index, row) {
      warningDialog("确定要删除吗？").then(() => {
        //删除操作
        this.delSection(index,row);
      });
    },
    // 删除题目
    handleItemDeleteQuestion(index, row){
      warningDialog("确定要删除吗？").then(() => {
        //删除操作
        this.delQuestion(index,row);
      });
    },

    //删除单词
    delSection(index,row) {
      var libId = sessionStorage.getItem("libId");
      letterBookWord({book_id:libId,letter_id:row.id,op: "delete"}).then(res=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除单词成功!");
        this.getlibIds();
      })
    },

    //删除题目
    delQuestion(index,row) {
      delTitleById({id:row.id}).then(res=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除题目成功!");
        this.getQuestionList(row.theme_id);
      })
    },
    
    choiceM(type) {
      if (type == "video") {
        this.popup = true;
        this.sucai.file_type = "video";
        this.getMaterialClassification();
      }
    },

    // 选择素材分页条数
    choiceSizeChange(limit) {
      this.sucai.limit = limit;
      selectMaterial(this.sucai).then((res) => {
        this.materialListes = res.data.data;
        this.choiceTotal = res.data.total;
      });
    },
    choiceCurrentChange(page) {
      this.sucai.page = page;
      selectMaterial(this.sucai).then((res) => {
        this.materialListes = res.data.data;
        this.choiceTotal = res.data.total;
      });
    },

    closed() {
      this.addLetterForm.word = "";
      this.addLetterForm.voice_file = "";
      this.addLetterForm.note = "";
      this.addLetterForm.examples = [{ sentence: "" }];
      this.audio_url = "";
      this.audioProgress = 0;
      this.fileAudioName = "";
    },

    // 点击操作栏的音频播放按钮
    toRadioPlay(item) {
      // console.log(item)
      if (item.voice_file == "") {
        this.$message.error("音频出错了");
        return;
      }
      let audio_play = new Audio(item.voice_file);
      audio_play.play();
    },
    // 添加小节
    handleAddSection() {
      var libId = sessionStorage.getItem("libId");
      this.addSectionDialog.addSectionVisible = false;
      var data = {
        lib_id: libId,
        chapter_name: this.addSectionDialog.chapter_name,
        file_id: this.addSectionDialog.file_id,
        unlock_time: this.addSectionDialog.unlock_time / 1000,
        is_task: this.addSectionDialog.is_task,
      };
      letterBookWord({book_id:libId,letter_id:this.selecteds.id,op: "add"}).then(res=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加单词成功!");
        this.getlibIds();
      })
    },
    // 选中素材，点击确认
    addMaterial() {
      this.popup = false;
    },
    // 选中素材
    selectImg(item) {
      this.selecteds = item;
      this.check_add_file_name = item.word;
    },
    // 下拉选择素材分类时触发
    getMaterialClassification() {
      letterCateList({type:this.typeid}).then(res=>{
        this.MaterialLists = res.data;
        if(this.sucai.cate_id == null){
          this.sucai.cate_id = res.data[0].id;
        }
        letterCateWordList({cate_id:this.sucai.cate_id}).then(res=>{
          res.data.forEach(item=>{
            item.examples = JSON.parse(item.example)[0].sentence
          })
          this.materialListes = res.data;
          // this.choiceTotal = res.data.total;
        })
      })
    },
    // 点击添加单词
    addSection() {
      if (this.selecteds3 == "") {
        this.$message.error("请选择章节分类!");
        return;
      }
      this.addSectionDialog.addSectionVisible = true;
      this.isAdds = "add";
      this.addFileDialogTitle = "添加单词"
    },
    // 点击添加题目
    addQuestion() {
      if (this.selecteds3 == "") {
        this.$message.error("请选择关卡等级!");
        return;
      }
      this.questionDialog = true;
      this.isAddsQuestion = "add";
      this.addQuestionTitle = "添加题目"
      this.getlibIds()
    },
    // 点击单元列表
    getSectionData(row, column, event) {
      sessionStorage["libId"] = row.id;
      this.selecteds3 = row;
      this.isSectionDisabled = false;
      this.getlibIds();
      this.isWord = true;
      this.isQuestion = false;
    },
    // 点击题目列表按钮
    getQuestionData(row){
      sessionStorage["libId"] = row.id;
      this.selecteds3 = row;
      this.isSectionDisabled = false;
      this.isWord = false;
      this.isQuestion = true;
      this.getQuestionList(row.id);
    },
    getlibIds() {
      var libId = sessionStorage.getItem("libId");
      var params = {
        book_id: libId,
      };
      letterBookWordList(params).then((res) => {
        this.selectWordsArr = [];
        this.distractorsArrList = [];
        if(Array.isArray(res.data)){
          res.data.forEach(item=>{
            console.log(item)
            item.examples = JSON.parse(item.example)
            let obj = {};
            obj.value = item.id;
            obj.label = item.word + ":" + item.note;
            obj.audio = item.voice_file;
            this.selectWordsArr.push(obj);
            this.distractorsArrList.push(obj);
          })
          this.sectionTable.data = res.data;
          // this.sectionTable.pageTotal = res.data.totalRows;
        }else{
          this.sectionTable.data = [];
        }
      });
    },
    // 获取题目列表
    getQuestionList(id){
      titleListByThemeId({ theme_id: id , theme_type : 30}).then((res)=>{
        res.data.forEach((item,index)=>{
          item.stem = "";
          item.optionArr = JSON.parse(item.options);
          item.titleLength = Object.keys(item.optionArr.right).length;
          item.titleArr = item.title.split(",");
          item.titleArr.forEach((item1,index1)=>{
            if(item.stem.length == 0){
              if(item.title_class == "other_to_ch"){
                item.stem = item.optionArr.right[item1].word;
              }else if(item.title_class == "ch_to_other"){
                item.stem = item.optionArr.right[item1].note;
              } 
            }else{
              if(item.title_class == "other_to_ch"){
                item.stem = item.stem + "," + item.optionArr.right[item1].word;
              }else if(item.title_class == "ch_to_other"){
                item.stem = item.stem + "," + item.optionArr.right[item1].note;
              } 
            }
          })
          // for(let i in item.optionArr.right){
          //   if(item.stem.length == 0){
          //     if(item.title_class == "other_to_ch"){
          //       item.stem = item.stem + item.optionArr.right[i].word;
          //     }else if(item.title_class == "ch_to_other"){
          //       item.stem = item.stem + item.optionArr.right[i].note;
          //     } 
          //   }else{
          //     if(item.title_class == "other_to_ch"){
          //       item.stem = item.stem + "," + item.optionArr.right[i].word;
          //     }else if(item.title_class == "ch_to_other"){
          //       item.stem = item.stem + "," + item.optionArr.right[i].note;
          //     } 
          //   }
          // }
        })
        this.questionList = res.data;
        console.log(this.questionList)
      })
    },
    // 点击章节列表删除图标
    async removeTab(id) {
      const result = await this.$confirm(
        "请问是否要永久删除该章节?",
        "删除提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (result !== "confirm") return this.$message.info("您已经撤销删除");

      letterBookDel({id:id}).then((res) => {
        if (res == undefined) {
          return;
        }
        if (res.code === 1) {
          letterBookList({type: this.typeid, parent_id: this.addDialog.id}).then(res=>{
            console.log(res)
            this.chapterTable.data = res.data;
          })
          this.$message.success("删除章节成功");
        }
      });
    },
    // 点击章节列表编辑图标
    eddChapter(item) {
      this.editChapterForm.id = item.id;
      this.editChapterForm.lib_name = item.name;
      this.editChapterForm.level = item.level;
      this.editChapterDialog = true;
    },
    // 点击立即编辑章节
    editChapterNow() {
      this.$refs.editChapterForm.validate((valid) => {
        if (valid) {
          if(this.editChapterForm.lib_name == ""){
            this.$message.error("请输入章节名");
            return;
          }else if(this.editChapterForm.level == "" && this.typeid == 30){
            this.$message.error("请输入等级");
            return;
          }
          letterBookUpdate({id: this.editChapterForm.id, name: this.editChapterForm.lib_name,level:this.editChapterForm.level,}).then((res) => {
            if (res.code !== 1){
              return this.$message.error("编辑章节失败,请重试");
            }
            this.$message.success("编辑章节成功！");
            this.editChapterDialog = false;
            letterBookList({type: this.typeid, parent_id: this.addDialog.id}).then(res=>{
              console.log(res)
              this.chapterTable.data = res.data;
            })
          });
        } else {
          return false;
        }
      });
    },
    // 章节弹窗
    handleItemChapter(index, row) {
      this.eddChapterDialog.editchapterVisible = true;
      this.addDialog.id = row.id;
      letterBookList({type: this.typeid, parent_id: row.id}).then(res=>{
        this.chapterTable.data = res.data;
      })
    },
    // 添加章节
    handleAddChapter() {
      if(this.addChapter.addChapterName == null){
        this.$message.error("请输入章节名");
        return;
      }
      var data = {
        type: this.typeid,
        parent_id: this.addDialog.id,
        name: this.addChapter.addChapterName,
        level: this.addChapter.level,
      };
      letterBookAdd(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.addChapter.adddialogVisible = false;
        this.$message.success("添加章节成功!");
        letterBookList({type: this.typeid, parent_id: this.addDialog.id}).then(res=>{
          console.log(res)
          this.chapterTable.data = res.data;
        })
      });
    },
    // 关闭添加词书弹框
    closedAdd(){
      this.addDialog = {
        open: 1,
        type: null,
        name: "",
        parent_id: 0,
        cover: "",
        adddialogVisible: false,
      }
    },
    // 点击添加词书按钮
    toAdd() {
      this.addImages = "";
      this.addDialog.adddialogVisible = true;
      this.isAdd = "add";
      this.addDialogTitle = "添加词书"
    },
    // 点击删除词书列表删除图标
    handleItemDelete(index, row) {
      warningDialog("确定要删除吗？").then(() => {
        this.delLetterBook(row);
      });
    },
    // 删除词书
    delLetterBook(row) {
      letterBookDel({id: row.id}).then((res) => {
        if (res == undefined) {
          return;
        }
        if (res.code === 1) {
          this.$message.success("删除词书成功!");
          this.getLetterBookList();
        }
      });
    },
    // 点击词书列表编辑图标
    async handleItemEdit(index, row) {
      this.addDialog.adddialogVisible = true;
      this.isAdd = "edit";
      this.addDialogTitle = "编辑词书";
      this.addDialog.id = row.id;
      this.addDialog.name = row.name;
      this.addDialog.cover = row.cover;
      this.addImages = row.http_cover;
      this.addDialog.open = row.open;
    },
    // 上传封面
    // beforeThumbUpload(file) {
    //   console.log(file)
    //   let that = this;
    //   const is1M = file.size / 1024 / 1024 < 10;
    //   const isSize = new Promise(function (resolve, reject) {
    //     let width = 246; 
    //     let height = 336;
    //     let _URL = window.URL || window.webkitURL;
    //     let img = new Image();
    //     img.onload = function () {
    //       let valid = img.width === width && img.height === height;
    //       valid ? resolve() : reject();
    //     };
    //     img.src = _URL.createObjectURL(file);
    //   }).then(
    //     () => {
    //        getReadCosToken({ suffix: file.name }).then((res) => {
    //         var cos = new COS({
    //           getAuthorization: function (options, callback) {
    //             callback({
    //               TmpSecretId: res.data.credentials.tmpSecretId,
    //               TmpSecretKey: res.data.credentials.tmpSecretKey,
    //               XCosSecurityToken: res.data.credentials.sessionToken,
    //               StartTime: res.data.startTime,
    //               ExpiredTime: res.data.expiredTime,
    //               expiration: res.data.expiration,
    //               requestId: res.data.requestId,
    //             });
    //           },
    //         });
    //         cos.putObject(
    //           {
    //             Bucket: res.data.Bucket,
    //             Region: res.data.Region,
    //             Key: res.data.key,
    //             // ContentType:'multipart/form-data',
    //             Body: file, // 上传文件对象
    //             onProgress: function (progressData) {},
    //           },
    //           function (err, data) {
    //             if (data) {
    //               that.addDialog.cover = res.data.key;
    //               const reader = new FileReader();
    //               reader.readAsDataURL(file);
    //               reader.onload = (e) => {
    //                 that.addImages = e.target["result"]; // 获取文件装换后的Base64位文件
    //                 that.$forceUpdate();
    //               };
    //             }
    //           }
    //         );
    //       });
    //       return file;
    //     },
    //     () => {
    //       that.$message.error(
    //         "上传封面图尺寸限制为246 x 336，大小不可超过10MB"
    //       );
    //       // return Promise.reject();
    //     }
    //   );
    // },
    beforeThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      getReadCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addDialog.cover = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.addImages = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },
    // 立即添加词书
    addBookNow(){
      this.addDialog.type = this.typeid;
      if(this.addDialog.name == ""){
        this.$message.error("请输入词书名");
        return;
      }else if(this.addDialog.cover == ""){
        this.$message.error("请上传封面图");
        return;
      }
      letterBookAdd(this.addDialog).then(res=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.addDialog.adddialogVisible = false;
        this.$message.success("添加词书成功!");
        this.getLetterBookList();
      })
    },
    // 立即编辑词书
    editBookNow(){
      if(this.addDialog.name == ""){
        this.$message.error("请输入词书名");
        return;
      }else if(this.addDialog.cover == ""){
        this.$message.error("请上传封面图");
        return;
      }
      letterBookUpdate(this.addDialog).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.addDialog.adddialogVisible = false;
        this.$message.success("编辑词书成功!");
        this.getLetterBookList();
      });
    },
    // 词书列表
    getLetterBookList(){
      letterBookList({type: this.typeid,parent_id: 0}).then(res=>{
        this.courseTable.data = [];
        this.courseTable.data = res.data;
        this.courseTable.total = res.data.total;
      })
    },
  },
  created() {
    this.getLetterBookList();
  },
  mounted() {
   
  },
  computed: {
    selectArray() {
      return this.list.filter((e) => e.vv_selected).map((e) => e.id);
    },
  },
};
</script>

<style scoped lang="less">
.red {
  color: #ff0000;
}
.set {
  color: #ebb563;
}
.inputText {
  position: relative;
  display: inline-block;
}
.radiusbg {
  border-radius: 2px;
  background-color: #ffffff;
  padding: 0px 0px 10px 0px;
  margin-top: 10px;
}
.rightview {
  display: inline-block;
}
.table {
  margin-top: 20px;
  background: #ffffff;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-aside {
  color: #333;
}

li {
  list-style: none;
  cursor: pointer;
}

.left ul {
  font-size: 14px;
  color: #333333;
}
.left ul li {
  padding-left: 20px;
  line-height: 50px;
  border-bottom: 1px solid #eeeeee;
}
.left ul .selected {
  background-color: #f5f7fa;
}
.right {
  flex: 10;
  margin-left: 20px;
}
.headers {
  border-bottom: 1px solid #eeeeee;
  width: 100%;
  height: 40px;
}
.headers ul {
  width: 100%;
}
.headers .selected {
  color: #38a28a;
  border-bottom: 1px solid #38a28a;
}
.headers li {
  height: 40px;
  float: left;
  margin-right: 60px;
}
::v-deep .el-form-item__label {
  color: #333333;
}
.ck {
  float: right;
}
.ck i {
  margin-right: 10px;
}
.ck i:nth-child(1) {
  color: #3fbfa2;
}
.ck i:nth-child(2) {
  color: red;
}
.navs .search .inputs {
  width: 180px;
}
::v-deep .el-form-item__content {
  vertical-align: 0;
}

.search {
  display: flex;
  line-height: 70px;
}

.contents {
  width: 999px;
  padding: 20px;
}
.material {
  position: relative;
  display: inline-block;
  width: 530px;
  height: 60px;
  margin: 0 20px 0 0;
  text-align: center;
}

// .material .tag {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 40px;
//   height: 20px;
//   text-align: center;
//   line-height: 20px;
//   background-color: #999;
//   opacity: 0.8;
//   color: #f5f7fa;
// }
.material .active {
  border: 2px solid #37a58c;
  z-index: 1000;
}
.material img {
  width: 100%;
  height: 118px;
}
.material .wordrow {
  display: flex;
  justify-content: center;
}
.material p {
  font-size: 14px;
  color: #333333;
  line-height: 26px;
  max-width: 530px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}
// .material .types {
//   display: flex;
//   justify-content: space-between;
// }
// .material .types .types-left img {
//   width: 18px;
//   height: 18px;
// }
// .material .types span {
//   color: #969696;
//   font-size: 12px;
//   margin-left: 10px;
// }
.pagination {
  display: flex;
  justify-content: space-between;
}

.types .types-right i:nth-child(1) {
  margin-right: 10px;
}
.types .types-right i:nth-child(2) {
  color: red;
}
.el-col {
  cursor: pointer;
}
.icon-shipin {
  color: #feaf65;
  font-size: 116px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 83px;
  height: 117px;
  line-height: 117px;
  text-align: center;
}

.chapterBody {
  position: fixed;
  z-index: 1009;
  overflow: auto;
  left: 200px;
  -webkit-box-shadow: 0px 2px 5px #666666;
  box-shadow: 0px 2px 5px #666666;
}
.top_btn_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top_btn_left {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titles {
      padding-left: 26px;
    }
    .cateButton {
      width: 200px;
      background-color: #38a28a;
      text-align: center;
      height: 34px;
      color: #fff;
      padding-top: 14px;
    }
  }

  .sectionButton {
    width: 200px;
    background-color: #1aa779;
    display: inline-block;
    text-align: center;
    color: #fff;
  }
  .not_sectionButton {
    width: 200px;
    background-color: #eeeeee;
    display: inline-block;
    text-align: center;
    color: #323232;
    font-weight: bold;
  }
}

.handle-box {
  position: relative;
  margin-right: 10px;
}
.iconfont {
  color: #3dccab;
}
.flex_box {
  width: 100%;
  display: flex;
}
.flex-left {
  margin: 0 20px;
  width: 40%;
  height: 750px;
  height: auto;
  overflow-y: auto;
}

.flex-right {
  width: 60%;
  height: 750px;
  // height: auto;
  overflow-y: auto;
}

.cateHeader {
  background-color: #f5f7fa;
  height: 34px;
  padding-top: 14px;
  text-align: center;
}

.is-center li {
  height: 36px;
  padding: 14px 0 0 14px;
  border-bottom: 1px solid #ebeef5;
}
.is-center .active {
  background-color: #38a28a;
  color: #fff;
}
.red {
  color: #ff0000;
}
.inputText {
  position: relative;
  display: inline-block;
}
.radiusbg {
  border-radius: 2px;
  background-color: #ffffff;
  padding: 0px 0px 10px 0px;
  margin-top: 10px;
}
.rightview {
  display: inline-block;
}
.table {
  background: #ffffff;
  width: 100%;
}

li {
  list-style: none;
  cursor: pointer;
}

.left ul {
  font-size: 14px;
  color: #333333;
}
.left ul li {
  padding-left: 20px;
  line-height: 50px;
  border-bottom: 1px solid #eeeeee;
}
.left ul .selected {
  background-color: #f5f7fa;
}
.right {
  flex: 10;
  margin-left: 20px;
}
::v-deep .el-form-item__label {
  color: #333333;
}
.ck {
  float: right;
}
.ck i {
  margin-right: 10px;
}
.ck i:nth-child(1) {
  color: #3fbfa2;
}
.ck i:nth-child(2) {
  color: red;
}
.navs .search .inputs {
  width: 180px;
}
::v-deep .el-form-item__content {
  vertical-align: 0;
  /* height: 30px; */
}

.search {
  display: flex;
  /* justify-content: space-between; */
  line-height: 70px;
}
.search .search-left {
  margin-left: 50px;
}

.contents {
  width: 999px;
  padding: 20px;
}
// .material {
//   position: relative;
//   display: inline-block;
//   width: 200px;
//   height: 60px;
//   margin: 0 20px 0 0;
//   text-align: center;
// }
// .material img {
//   width: 100%;
//   height: 118px;
// }
// .material p {
//   font-size: 14px;
//   color: #333333;
//   line-height: 26px;
//   max-width: 190px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }
// .material .types {
//   display: flex;
//   justify-content: space-between;
// }
// .material .types .types-left img {
//   width: 18px;
//   height: 18px;
// }
// .material .types span {
//   color: #969696;
//   font-size: 12px;
//   margin-left: 10px;
// }
.pagination {
  display: flex;
  justify-content: space-between;
}

.types .types-right i:nth-child(1) {
  margin-right: 10px;
}
.types .types-right i:nth-child(2) {
  color: red;
}
.el-col {
  cursor: pointer;
}
.icon-shipin {
  color: #feaf65;
  font-size: 116px;
}

// .avatar-uploader .el-upload {
//   border: 1px dashed #d9d9d9;
//   border-radius: 6px;
//   cursor: pointer;
//   position: relative;
//   overflow: hidden;
// }
// .avatar-uploader .el-upload:hover {
//   border-color: #409eff;
// }
// .avatar-uploader-icon {
//   font-size: 28px;
//   color: #8c939d;
//   width: 80px;
//   height: 80px;
//   line-height: 180px;
//   text-align: center;
// }
// .avatar {
//   width: 178px;
//   height: 178px;
//   display: block;
// }
.generate {
  float: right;
}

// ::v-deep .el-dialog {
//   margin-top: 10vh;
// }
.pagination {
  display: flex;
  justify-content: space-between;
}

.footer-btn {
  display: flex;
  justify-content: space-evenly;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 200px;
  height: 112px;
}

/deep/ .el-upload--text {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 83px;
  height: 117px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/.el-cascader .el-input {
  width: 500px;
}
</style>
