import request from "@/utils/request";
import { searchByCriteria } from "./chapter";

export function getChapterList(query) {
    return request({
        url: '/admin/base/goodsLibList',
        method: 'get',
        params: query
    }
    )
}

export function editChapters(data) {
  return request ({
    url: "/admin/base/goodsLibEdit",
    method: "put",
    params: data
  });
}

export function addChapter(data) {
  return request ({
    url: "/admin/base/goodsLibAdd",
    method: "post",
    data
  });
}

export function delChapter(data) {
  return request ({
    url: "/admin/base/goodsLibDel",
    method: "delete",
    data: {
      id:data
    }
  });
}

export function getSectionList(data) {
  return request ({
    url: "/admin/base/goodsChapterList",
    method: "get",
    params: data
  })
}

export function addSection(data) {
  return request ({
    url: "/admin/base/goodsChapterAdd",
    method: "post",
    params: data
  })
}

export function editSections(data) {
  return request ({
    url: "/admin/base/goodsChapterEdit",
    method: "put",
    params: data
  })
}

export function delSection(data) {
  return request ({
    url: "/admin/base/goodsChapterDel",
    method: "delete",
    data: {
      id:data
    }
  })
}
