import request from "@/utils/request";

// 词书/单词列表
export function letterBookList(params) {
  return request({
      url: '/admin/base/letterBookList',
      method: 'get',
      params
  })
}

// 删除词书/单词
export function letterBookDel(data) {
  return request({
      url: '/admin/base/letterBookDel',
      method: 'delete',
      data
  })
}

// 添加词书/单词
export function letterBookAdd(data) {
  return request({
      url: '/admin/base/letterBookAdd',
      method: 'post',
      data
  })
}

// 修改词书/单词
export function letterBookUpdate(data) {
  return request({
      url: '/admin/base/letterBookUpdate',
      method: 'post',
      data
  })
}

// 单元单词列表
export function letterBookWordList(params) {
  return request({
      url: '/admin/base/letterBookWordList',
      method: 'get',
      params
  })
}

// 添加、删除词书里面的单词
export function letterBookWord(data) {
  return request({
      url: '/admin/base/letterBookWord',
      method: 'post',
      data
  })
}